





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SlidePop extends Vue {
  @Prop({ required: true }) show: boolean
  @Prop({ default: 0 }) slide: number
  @Prop({}) title: string
  @Prop({ default: false }) withFooter: boolean

  slide2Height = 0

  @Watch('show')
  handleShow(isShow) {
    if (isShow) {
      this.$nextTick(() => {
        const el = document.querySelector('.slide-1.show')
        if (el) {
          this.slide2Height = el.getBoundingClientRect().height
        }
      })
    }
  }

  @Watch('slide')
  handleSlideChange() {
    if (this.show) {
      this.$nextTick(() => {
        const el = document.querySelector('.slide-1.show')
        if (el) {
          this.slide2Height = el.getBoundingClientRect().height
        }
      })
    }
  }

  handleCancel() {
    this.$emit('close')
  }
}
