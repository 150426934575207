import {
  AjaxBasics,
  ControllerAbout,
  ControllerActivitys,
  ControllerCourse,
  ControllerGroupBuy,
  ControllerHome,
  ControllerHomework,
  ControllerMy,
  ControllerOrder,
  ControllerOSS,
  ControllerProduct,
  ControllerShow,
  ControllerStationery,
  ControllerTicket,
  ControllerUser,
  ControllerVideo,
  ControllerWechat,
  ControllerGenseeLive,
  ControllerGenseePlayback,
  ControllerCoupon,
  EnumApiCurrency,
  EnumApiUser,
  ControllerDevice,
  StorageKeys
} from '@xt/client'
import lodash from 'lodash'
import { create, persist } from 'mobx-persist'
// import NProgress from 'nprogress';
import { TimeoutError } from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import { Toast } from 'vant'
import { getDeviceInfo } from '@xt/client/utils/device'
import { getTraceId, getXTDevId } from '@xt/client/utils/uuid'
import { getFp, getUserId } from '@xt/client/utils/fingerprint/report'
import $global, { XTGlobal } from './global'
import { XTLocale } from './locale'
import { XTMenu } from './menu'
import { AccountAlertInfo } from '@xt/client/entities/user/types'
import { parseAccountBanInfo } from '@xt/client/utils/user'

// const router = require('@xt/client/config/router');

// 本地代理 小写
if ($global.dev) {
  AjaxBasics.serviceDateKey = 'date'
}

async function reportDeviceInfo(ajax: AjaxBasics, store: { $storeUser: ControllerUser; $global: XTGlobal }) {
  const { changed: fpChanged, current: currentFp, before: beforeFp, extra: fpExtra } = await getFp(store.$global)

  const devInfo = await getDeviceInfo()

  const { changed: userIdChanged, current: currentId, before: beforeId } = getUserId(store.$storeUser, store.$global)

  if (!fpChanged && !userIdChanged) {
    // 指纹稳定，没有发生变化。不重复上报
    return
  }

  ajax.post(EnumApiUser.AddDeviceLog, {
    // 喧桐设备指纹（自己生成的）
    xtDeviceId: currentFp,
    // 老的设备指纹
    xtDeviceIdOld: beforeFp,
    // 此次生成的设备指纹
    deviceId: getXTDevId(store.$global.platform),
    // 是否发生指纹变更
    deviceIdChange: fpChanged,
    // 当前的用户id
    memberId: currentId,
    appId: store.$global.xtAppid,
    ua: navigator.userAgent || '',
    logVersion: $global.version,
    audio: fpExtra.audio,
    webgl: fpExtra.webgl,
    canvas: fpExtra.canvas,
    extra: JSON.stringify({
      ...devInfo,
      location: location.href,
      embeddedH5: store.$global.isEmbeddedH5Page,
      embeddedProgrem: store.$global.embeddedProgrem,
      beforeId,
      userIdChanged,
      trace: getTraceId()
    })
  })
}

/**
 * 防止用户修改devId和trace
 */
// function listenStorageChanged(event: StorageEvent) {
//   console.warn('listenStorageChanged', event)
//   const { oldValue, newValue, key } = event

//   const do_not_modify_keys = [
//     StorageKeys.LastDevIdH5,
//     StorageKeys.LastDevIdPC,
//     StorageKeys.TraceId
//   ] as const

//   if (do_not_modify_keys.includes(key)) {
//     // 新生成
//     if (!oldValue) return

//     console.log('用户篡改了敏感数据!')
//   }
// }

/**
 * 创建 全局状态
 * @param ajax
 * 本地使用代理 $global.dev ? '/devTarget' : $global.target
 */
export function onCreateRootStore(
  ajax: AjaxBasics = new AjaxBasics({
    target: $global.dev ? '/devTarget' : $global.target
  })
) {
  // export function onCreateRootStore(ajax: AjaxBasics = new AjaxBasics({ target: $global.target })) {
  const RootStore = {
    // 全局
    $global: $global,
    // ajax
    $ajax: ajax,
    // 设备信息
    $device: new ControllerDevice(ajax),
    // 语言
    $locale: new XTLocale(),
    // 菜单w
    $menu: new XTMenu(),
    // 首页
    $storeHome: new ControllerHome(ajax),
    // 课程
    $storeCourse: new ControllerCourse(ajax),
    // 活动
    $storeActivitys: new ControllerActivitys(ajax),
    // 文房
    $storeStationery: new ControllerStationery(ajax),
    // 视频
    $storeVideo: new ControllerVideo(ajax),
    // 关于
    $storeAbout: new ControllerAbout(ajax),
    // 订单
    $storeOrder: new ControllerOrder(ajax),
    // 作业
    $storeHomework: new ControllerHomework(ajax),
    // 文房券
    $storeTicket: new ControllerTicket(ajax),
    // 展览
    $storeShow: new ControllerShow(ajax),
    // 用户
    $storeUser: new ControllerUser(ajax),
    // 微信
    $wechat: new ControllerWechat(ajax, $global.appid),
    // 我的
    $my: new ControllerMy(ajax),
    // oss 服务
    $OSS: new ControllerOSS(ajax),
    // 拼团
    $storeGroup: new ControllerGroupBuy(ajax),
    // 商品
    $Product: new ControllerProduct(ajax),
    // 展示互动直播
    $genseeLive: new ControllerGenseeLive(ajax),
    // 展示互动回放
    $genseePlayback: new ControllerGenseePlayback(ajax),
    $coupon: new ControllerCoupon(ajax)
  }

  RootStore.$storeUser.appletAuth.initAuthLogin()

  setTimeout(() => {
    reportDeviceInfo(ajax, RootStore).catch(() => {})
    // window.removeEventListener('storage', listenStorageChanged);
    // window.addEventListener('storage', listenStorageChanged);
  }, 1000)

  /**
   * 配置缓存数据
   */
  async function onCreatePersist() {
    // https://github.com/pinqy520/mobx-persist
    const hydrate = create({
      // storage: window.localStorage,   // or AsyncStorage in react-native.
      // default: localStorage
      // jsonify: true  // if you use AsyncStorage, here shoud be true
      // default: true
    })
    // 配置缓存字段
    persist({ locale: true })(RootStore.$locale)
    persist({ Banners: { type: 'list' } })(RootStore.$storeHome)
    // persist({ typelist: { type: "list" } })(RootStore.$storeStationery);
    // persist({ typelist: { type: "list" } })(RootStore.$storeAbout);
    // persist({ typelist: { type: "list" } })(RootStore.$storeOrder);
    persist({ UserInfo: { type: 'object' } })(RootStore.$storeUser)
    // 设置类
    hydrate(`${$global.localStorageStartsWith}locale`, RootStore.$locale)
    hydrate(`${$global.localStorageStartsWith}Home`, RootStore.$storeHome)
    // hydrate(
    //     `${$global.localStorageStartsWith}Stationery`,
    //     RootStore.$storeStationery
    // );
    // hydrate(`${$global.localStorageStartsWith}About`, RootStore.$storeAbout);
    // hydrate(`${$global.localStorageStartsWith}Order`, RootStore.$storeOrder);
    await hydrate(`${$global.localStorageStartsWith}User`, RootStore.$storeUser)
    // if ($global.dev) {
    //     RootStore.$storeUser.onTextToken();
    // }
    RootStore.$storeUser.onGetUserInfo()
  }

  /**
   * 扩展 AjaxBasics 配置
   */
  function onResetAjaxBasics(params: { msg: (error) => void }) {
    AjaxBasics.onMergeHeaders = function (headers, request) {
      const accessToken = RootStore.$storeUser.UserInfo.access_token
      if (accessToken) {
        // 认证授权
        return {
          Authorization: `bearer ${RootStore.$storeUser.UserInfo.access_token}`
        }
      }
    }
    AjaxBasics.AjaxObservableStart = async function (request) {
      return await RootStore.$storeUser.onExpirationRefresh(request)
    }
    // 过滤
    AjaxBasics.onFilter = function (res) {
      // 数据 Response
      if (res instanceof AjaxResponse) {
        // 无 响应 数据
        // if (lodash.isNil(res.response)) {
        //     // throw lodash.merge(res, $global.production ? { message: '服务器开小差了' } : { message: '响应体不存在' })
        // }
        // else if (!lodash.eq(lodash.get(res.response, 'code', 0), 0)) {
        //     throw lodash.merge(res, { message: lodash.get(res.response, 'msg') })
        // }
        if (lodash.isBoolean(res.response) && lodash.eq(false, res.response)) {
          throw false
        }
      }
      // 错误 超时
      if (res instanceof AjaxError || res instanceof TimeoutError) {
        console.error('LENG: AjaxBasics.onFilter -> res', res)
        // 跳过的 api
        const url = lodash.get(res, 'request.url')

        // 完全忽略
        if (lodash.endsWith(url, '/date')) {
          throw false
        }

        if (lodash.includes(url, '/course/api/materials/')) {
          return false
        }
        if (lodash.isString(lodash.get(res, 'response'))) {
          lodash.update(res, 'response', x => {
            try {
              return JSON.parse(x)
            } catch (error) {
              return x
            }
          })
        }
        throw lodash.assign(res, {
          message: lodash.head(
            lodash.compact([
              lodash.get(res, 'response.errMsg'),
              lodash.get(res, 'response.error_description'),
              lodash.get(res, 'response.error')
              // '网络开小差了'
            ])
          )
        })
      }
      return true
    }
    AjaxBasics.onMap = function (res) {
      if (lodash.eq(res.responseType, 'blob')) {
        return res
      }
      return lodash.get(res, 'response')
    }
    AjaxBasics.onError = async function (error) {
      setTimeout(() => {
        console.log('LENG ~ onCreateRootStore ~ error', error)
      }, 300)
      // 设备/用户禁用封禁
      if (
        lodash.eq(lodash.get(error, 'status'), 403) &&
        [201100015 /** OAuth 设备已封禁 */, 201100017 /** OAuth 账号已封禁 */].includes(error.response.errCode)
      ) {
        const memberId = error.response.errMsg
        if ($global.platform === 'PC') {
          window.$nuxt.$store.$storeUser.onToggleVisible(false)
        }

        // 获取封禁弹窗数据
        const _alertInfo = await ajax.get<AccountAlertInfo>(EnumApiUser.AccounBanInfo, {
          memberId,
          devId: $global.devId
        })
        const alertInfo = parseAccountBanInfo(_alertInfo)
        RootStore.$storeUser.setAccountBanInfo(alertInfo)

        return
      }

      if (lodash.eq(lodash.get(error, 'status'), 403) && error.response.errCode == 1031100001) {
        error && params.msg(error)
        await new Promise(r =>
          setTimeout(() => {
            r(true)
          }, 1500)
        )
        await RootStore.$storeUser.onOutLogin()
        if (RootStore.$global.isEmbeddedH5Page) {
          // 内嵌不准反回首页
          return
        }
        return window.location.assign(window.location.origin + RootStore.$global.base)
      }
      //账号注销中  进入冷静期页面
      if (lodash.eq(lodash.get(error, 'status'), 403) && error.response.errCode === 1031100058) {
        error && params.msg(error)
        RootStore.$storeUser.onGetUserInfo()
        return
      }
      if (lodash.eq(lodash.get(error, 'status'), 401)) {
        //账号已注销
        if (error.response.errCode == 1031100002) {
          error && params.msg(error)
          await new Promise(r =>
            setTimeout(() => {
              r(true)
            }, 1500)
          )
        }
        // if ($global.production) {
        await RootStore.$storeUser.onOutLogin()
        params.msg({ message: '登录失效' })
        if (RootStore.$global.isEmbeddedH5Page) {
          // 内嵌不准反回首页
          console.log('内嵌不准反回首页', error)
          wx.miniProgram.redirectTo({ url: `/pages/login/index?fallback=${encodeURIComponent(location.href)}` })
          return
        }
        setTimeout(() => {
          window.location.assign(window.location.origin + RootStore.$global.base)
        }, 500)
        return
        // }
        // error = { message: "你被顶掉了~懂？" }
      }
      // 登录的临时token失效
      if (error.response?.errCode == 1031100029) {
        Toast('验证码获取失败,请重新登录', {
          duration: 1500
        })

        const t = setTimeout(() => {
          clearTimeout(t)
          history.back()
        }, 1500)
        return
      }
      if (lodash.isEqual(error, { message: 'Internal Server Error' })) {
        if (!$global.production) {
          error = { message: '后台服务正在发布，等会儿~' }
        } else {
          error = { message: '网络开小差了' }
        }
      }

      // 以2开头的错误码前端不弹出toast
      if (`${error.response?.errCode}`.startsWith('2')) {
        return
      }

      error && params.msg(error)
    }
  }
  return {
    ajax,
    RootSrore: RootStore,
    onCreatePersist,
    onResetAjaxBasics
  }
}

// 扩展 ts
declare module 'vuex/types/index' {
  interface Store<S> {
    /** 请求 */
    $ajax: AjaxBasics
    /**
     * 全局公用
     */
    readonly $global: XTGlobal
    /**
     * 菜单
     */
    readonly $menu: XTMenu
    /**
     * 本地语音全局状态
     */
    readonly $locale: XTLocale
    /**
     * 首页状态控制器
     */
    readonly $storeHome: ControllerHome
    /**
     * 课程控制器
     */
    readonly $storeCourse: ControllerCourse
    /**
     * 展览控制器
     */
    readonly $storeShow: ControllerShow
    /**
     * 文房
     * @type {ControllerStationery}
     * @memberof Store
     */
    readonly $storeStationery: ControllerStationery
    /**
     * 活动
     */
    readonly $storeActivitys: ControllerActivitys
    /**
     * 视频
     * @type {ControllerStationery}
     * @memberof Store
     */
    readonly $storeVideo: ControllerVideo
    /**
     * 关于
     * @type {ControllerAbout}
     * @memberof Store
     */
    readonly $storeAbout: ControllerAbout
    /**
     * 订单
     * @type {ControllerOrder}
     * @memberof Store
     */
    readonly $storeOrder: ControllerOrder
    /**
     * 团购
     */
    readonly $storeGroup: ControllerGroupBuy

    readonly $storeHomework: ControllerHomework
    readonly $storeTicket: ControllerTicket
    /**
     * 用户
     * @type {ControllerUser}
     * @memberof Store
     */
    readonly $storeUser: ControllerUser
    /**
     * 微信
     * @type {ControllerMy}
     * @memberof Store
     */
    readonly $wechat: ControllerWechat
    /**
     * 我的
     * @type {ControllerMy}
     * @memberof Store
     */
    readonly $my: ControllerMy
    /**
     * oss 服务
     */
    readonly $OSS: ControllerOSS
    /**
     * 商品服务
     */
    readonly $Product: ControllerProduct

    /**
     * 设备管理
     */
    readonly $device: ControllerDevice

    /**
     * 展示互动直播
     */
    readonly $genseeLive: ControllerGenseeLive

    /**
     * 展示互动回放
     */
    readonly $genseePlayback: ControllerGenseePlayback

    readonly $coupon: ControllerCoupon
  }
}
