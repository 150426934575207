





















import { Component, Prop, Vue, Emit, Watch, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class CourseInfo extends Vue {
  @Prop({}) max: number
  @Prop({}) total: number
  @Prop({}) defaultValue: number
  value = 0
  minusBtnDisible = false
  plusBtnDisible = false

  @Watch('defaultValue', { immediate: true })
  handleDefaultValueChange(value: number) {
    console.log('handleDefaultValueChange', value)
    this.value = value
    this.handleValueChange(value)
  }

  @Watch('value')
  handleValueChange(value: number) {
    if (typeof value === 'number' && !Number.isNaN(value)) {
      console.log('value chang了', value)
      this.minusBtnDisible = value === 0
      this.plusBtnDisible = value === this.max
      this.$emit('change', value)
    }
  }

  handleInputChange(e) {
    const _value = parseInt(e.target.value, 10)

    if (Number.isNaN(_value)) {
      this.value = 0
    } else if (_value >= this.max) {
      this.value = this.max
    } else if (_value <= 0) {
      this.value = 0
    } else {
      this.value = _value
    }
  }

  handleChangeValue(type: 'plus' | 'minus', step: number) {
    const result = type === 'plus' ? this.value + step : this.value - step

    if (result > this.max) {
      this.value = this.max
    } else if (result < 0) {
      this.value = 0
    } else {
      this.value = result
    }
  }
}
