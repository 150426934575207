










import { Component, Prop, Vue, Provide, Inject, Mixins } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class GiftCoupon extends Vue {
  @Prop({}) count: number
}
