























import { Component, Prop, Vue } from 'vue-property-decorator'
import { CouponInfoListItem, BaseCouponItem } from '@xt/client/types/coupon'

@Component({
  components: {}
})
export default class GiftCouponPop extends Vue {
  @Prop({ default: false }) hideBackBtn

  @Prop({ required: true }) data: {
    list: CouponInfoListItem[]
    coupon: BaseCouponItem | null
  }
}
