






















import { Component, Prop, Vue, Provide, Inject, Mixins } from 'vue-property-decorator'
import SlidePop from './SlidePop.vue'
import CouponItem from './CouponItem.vue'
import CouponUseInfo from './CouponUseInfo.vue'
import { CouponInfoListItem, BaseCouponItem } from '@xt/client/types/coupon'

@Component({
  components: { SlidePop, CouponItem, CouponUseInfo }
})
export default class GiftCouponPop extends Vue {
  visible = false
  slide: 0 | 1 = 0

  secondViewInfo: {
    list: CouponInfoListItem[]
    coupon: BaseCouponItem | null
  } = {
    list: [],
    coupon: null
  }

  get CouponStore() {
    return this.$store.$coupon
  }

  show() {
    this.visible = true
  }

  hide() {
    this.visible = false
    this.handleAnimationClosed()
  }

  handleClickUseInfo(data: { list: CouponInfoListItem[]; coupon: BaseCouponItem }) {
    this.secondViewInfo = {
      list: data.list,
      coupon: data.coupon
    }
    this.slide = 1
  }

  handleAnimationClosed() {
    this.slide = 0
  }
}
