import { ClientInstance, SharedTabBroadcastChannel, Message } from './types'
import { SyncServerDate } from './utils'
import { SharedTabMessage } from './consts'

// 原则：谁最后打开谁处理任务
export default class SharedTabInstance implements ClientInstance {
  private channelName: string
  private tid: string
  private onMessage: (message: any) => void
  private channel: SharedTabBroadcastChannel
  private isCurrentTabVisible: boolean
  // 当前是哪个tab在执行任务
  private taskRunningTabId: string
  private syncServerDate: SyncServerDate
  private apiTarget: string

  constructor(options: { channelName: string; tid: string; onMessage: (message: any) => void; apiTarget: string }) {
    this.apiTarget = options.apiTarget
    this.channelName = options.channelName
    this.tid = options.tid
    this.onMessage = options.onMessage
    this.channel = new BroadcastChannel(this.channelName)
    this.isCurrentTabVisible = true
    this.syncServerDate = new SyncServerDate(60 * 1000, this.apiTarget)

    this.channel.onmessage = event => {
      this.handleChannelMessage(event.data)
    }

    this.handleDocumentVisibilityChange = this.handleDocumentVisibilityChange.bind(this)
    this.handleCloseTabPage = this.handleCloseTabPage.bind(this)
    this.onSyncServerDate = this.onSyncServerDate.bind(this)

    document.addEventListener('visibilitychange', this.handleDocumentVisibilityChange)
    window.addEventListener('beforeunload', this.handleCloseTabPage)

    this.taskRunningTabId = this.tid
    this.channel.postMessage({ type: SharedTabMessage.TabRunTask, payload: { tid: this.tid } })
    this.syncServerDate.start(this.onSyncServerDate)
  }

  private handleDocumentVisibilityChange() {
    this.isCurrentTabVisible = document.visibilityState === 'visible'

    if (this.isCurrentTabVisible && this.taskRunningTabId === '') {
      this.taskRunningTabId = this.tid
      this.channel.postMessage({ type: SharedTabMessage.TabRunTask, payload: { tid: this.tid } })
      this.syncServerDate.start(this.onSyncServerDate)
    }
  }

  private handleCloseTabPage() {
    if (this.taskRunningTabId === this.tid) this.channel.postMessage({ type: SharedTabMessage.TabPauseTask, payload: { tid: this.tid } })
    document.removeEventListener('visibilitychange', this.handleDocumentVisibilityChange)
  }

  private handleChannelMessage(message: Message<string, { tid: string }>) {
    switch (message.type) {
      case SharedTabMessage.TabRunTask:
        // console.warn('debug 收到了TabRunTask消息，中断当前tab的任务', message.payload.tid)
        this.taskRunningTabId = message.payload.tid
        this.syncServerDate.abortAndStopSync()
        break
      case SharedTabMessage.TabPauseTask:
        // console.warn('debug 收到了TabPauseTask消息，重新检测tab是否在前台', message.payload.tid)
        this.taskRunningTabId = ''
        this.handleDocumentVisibilityChange()
        break
      case SharedTabMessage.SyncServerDate:
        // 向外界同步
        this.onMessage(message)
        break
    }
  }

  private onSyncServerDate(data: { date: string; delay: number }) {
    const message = { type: SharedTabMessage.SyncServerDate, payload: { ...data, type: 2, executer: this.tid } }
    this.onMessage(message)
    this.channel.postMessage(message)
  }

  public async sendMessage(message: any): Promise<[boolean, null]> {
    return [true, null]
  }
}
