import { SharedSelfMessage } from './consts'
import { ClientInstance, Message } from './types'
import { SyncServerDate } from './utils'

// 8%的用户既不支持Shared Worker也不支持BroadcastChannel只能自己处理自己的任务
export default class SharedSelf implements ClientInstance {
  private tabId: string
  private onMessage: (message: any) => void
  private syncServerDate: SyncServerDate
  private apiTarget: string
  private visibleTimer: null | ReturnType<typeof setTimeout>

  constructor(options: { tid: string; onMessage: (message: any) => void; apiTarget: string }) {
    this.apiTarget = options.apiTarget
    this.tabId = options.tid
    this.onMessage = options.onMessage
    this.syncServerDate = new SyncServerDate(60 * 1000, this.apiTarget)

    this.handleDocumentVisibilityChange = this.handleDocumentVisibilityChange.bind(this)
    this.handleClosePage = this.handleClosePage.bind(this)
    this.onSyncServerDate = this.onSyncServerDate.bind(this)

    document.addEventListener('visibilitychange', this.handleDocumentVisibilityChange)
    window.addEventListener('beforeunload', this.handleClosePage)

    this.syncServerDate.start(this.onSyncServerDate)
  }

  private handleDocumentVisibilityChange() {
    if (document.visibilityState === 'hidden') {
      this.syncServerDate.abortAndStopSync()
    } else {
      // 重新聚焦后 强行等1分钟后再次开始任务
      if (this.visibleTimer) {
        clearTimeout(this.visibleTimer)
        this.visibleTimer = null
      }

      this.visibleTimer = setTimeout(() => {
        // 1分钟后如果用户还在当前页面，则再次开始同步
        if (document.visibilityState === 'visible') {
          this.syncServerDate.start(this.onSyncServerDate)
        }
      }, 60 * 1000)
    }
  }

  private handleClosePage() {
    document.removeEventListener('visibilitychange', this.handleDocumentVisibilityChange)
    this.syncServerDate.abortAndStopSync()
  }

  private onSyncServerDate(data: { date: string; delay: number }) {
    const message = { type: SharedSelfMessage.SyncServerDate, payload: { ...data, type: 3, executer: this.tabId } }
    this.onMessage(message)
  }

  public async sendMessage(message: any): Promise<[boolean, null]> {
    return [true, null]
  }
}
