























































































































































import { Component, Emit, Prop, Mixins } from 'vue-property-decorator'
import CircleGroup from './views/circleGroup/index.vue'
import { OrderDetails } from '@xt/client/mixins'
import { Observer } from 'mobx-vue'
import ViewDescItem from './views/item.vue'
import OrderProduct from '../../pages/my/children/order/views/product/product.vue'
import Product from '@xt/client/entities/basics/product'
import CouponItem from '../coupon/CouponItem.vue'
import GiftCouponPop from '../coupon/CouponUseInfo.vue'

@Observer
@Component({
  components: {
    CircleGroup,
    ViewDescItem,
    OrderProduct,
    CouponItem,
    GiftCouponPop
  }
})
export default class OrderDescPopup extends Mixins(OrderDetails) {
  @Prop() title: string
  @Prop({}) visible: boolean
  @Prop({}) orderNum: string
  @Prop() productInfo: Product
  @Prop() showRefundTime: boolean
  @Prop() refundStatus: boolean
  @Prop() refundEndDate: number
  @Prop() orderStatus: string
  @Prop() orderStatusStyle: string
  @Prop() virtualCurrency: number
  @Prop() getCoinPayPrice: number
  @Prop() payAmounts: number
  @Prop() showInvoiceIng: boolean
  @Prop() showInvoiceSuccess: boolean

  @Emit('hide')
  hidePopup() {}

  clickUseDescHandler(data: any) {
    this.couponDescInfo = data
    this.visibleCouponDesc = true
  }

  async created() {
    const store = this.$store.$storeOrder.orderDetail
    await store.onLoading({ orderNo: this.orderNum })
    const resp = await this.$store.$coupon.assocOrder.onGetCouponListByOrderNo(this.orderNum)
    if (Array.isArray(resp)) {
      this.orderAssocCouponList = resp
    }
    this.dataSource = this.Detail.dataSource
  }

  destroyed() {}
}
