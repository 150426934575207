import { InspectOptions } from 'util'

export default context => {
  if (context.store.$global.DEPLOY_ENV === 'pro') {
    // @ts-ignore
    globalThis.console = new (class EmptyConsole implements Console {
      assert(condition: boolean, ...data: any[]): void
      assert(value: any, message: string, ...optionalParams: any[]): void
      assert(value: unknown, message: unknown, ...optionalParams: unknown[]): void {}
      clear(): void
      clear(): void
      clear(): void {}
      count(label: string): void
      count(label: string): void
      count(label: unknown): void {}
      countReset(label: string): void
      countReset(label: string): void
      countReset(label: unknown): void {}
      debug(...data: any[]): void
      debug(message: any, ...optionalParams: any[]): void
      debug(message: unknown, ...optionalParams: unknown[]): void {}
      dir(item: any, options: any): void
      dir(obj: any, options: InspectOptions): void
      dir(obj: unknown, options: unknown): void {}
      dirxml(...data: any[]): void
      dirxml(...data: any[]): void
      dirxml(...data: unknown[]): void {}
      error(...data: any[]): void
      error(message: any, ...optionalParams: any[]): void
      error(message: unknown, ...optionalParams: unknown[]): void {}
      group(...data: any[]): void
      group(...label: any[]): void
      group(...label: unknown[]): void {}
      groupCollapsed(...data: any[]): void
      groupCollapsed(...label: any[]): void
      groupCollapsed(...label: unknown[]): void {}
      groupEnd(): void
      groupEnd(): void
      groupEnd(): void {}
      info(...data: any[]): void
      info(message: any, ...optionalParams: any[]): void
      info(message: unknown, ...optionalParams: unknown[]): void {}
      log(...data: any[]): void
      log(message: any, ...optionalParams: any[]): void
      log(message: unknown, ...optionalParams: unknown[]): void {}
      table(tabularData: any, properties: string[]): void
      table(tabularData: any, properties: readonly string[]): void
      table(tabularData: unknown, properties: unknown): void {}
      time(label: string): void
      time(label: string): void
      time(label: unknown): void {}
      timeEnd(label: string): void
      timeEnd(label: string): void
      timeEnd(label: unknown): void {}
      timeLog(label: string, ...data: any[]): void
      timeLog(label: string, ...data: any[]): void
      timeLog(label: unknown, ...data: unknown[]): void {}
      timeStamp(label: string): void
      timeStamp(label: string): void
      timeStamp(label: unknown): void {}
      trace(...data: any[]): void
      trace(message: any, ...optionalParams: any[]): void
      trace(message: unknown, ...optionalParams: unknown[]): void {}
      warn(...data: any[]): void
      warn(message: any, ...optionalParams: any[]): void
      warn(message: unknown, ...optionalParams: unknown[]): void {}
      Console: NodeJS.ConsoleConstructor
      profile(label: string): void {}
      profileEnd(label: string): void {}
    })()
  }
}
