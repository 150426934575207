import { render, staticRenderFns } from "./CouponUseInfo.vue?vue&type=template&id=473697cc&scoped=true&"
import script from "./CouponUseInfo.vue?vue&type=script&lang=ts&"
export * from "./CouponUseInfo.vue?vue&type=script&lang=ts&"
import style0 from "./CouponUseInfo.vue?vue&type=style&index=0&id=473697cc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473697cc",
  null
  
)

export default component.exports