import { reportEntryLog } from '@xt/client/utils/log'

export class SyncServerDate {
  private isClientSupportAbortSignal: boolean
  private syncRequestDelay: number
  private controller: null | AbortController
  private loopTimer: null | ReturnType<typeof setTimeout>
  private isRunning: boolean
  private apiTarget: string

  constructor(syncRequestDelay: number, apiTarget: string) {
    this.apiTarget = apiTarget
    this.syncRequestDelay = syncRequestDelay
    this.isClientSupportAbortSignal = 'AbortController' in window
  }

  public async start(listener: (data: { date: string; delay: number }) => void) {
    if (this.isRunning) return

    try {
      this.isRunning = true

      if (this.isClientSupportAbortSignal) {
        this.controller = new AbortController()
      }

      const start = Date.now()
      const response = await fetch(`${this.apiTarget}/date`, {
        signal: this.controller?.signal ?? null
      })
      const end = Date.now()
      const date = response.headers.get('Date')
      listener({ date, delay: end - start })
    } catch (e) {
      reportEntryLog('utils.server-date-sync.error', { e, abortSignal: this.isClientSupportAbortSignal, apiTarget: this.apiTarget })
    } finally {
      this.loopTimer = setTimeout(() => {
        this.isRunning = false
        this.start(listener)
      }, this.syncRequestDelay)
    }
  }

  public abortAndStopSync() {
    if (this.controller) {
      this.controller.abort()
    }

    if (this.loopTimer) {
      clearTimeout(this.loopTimer)
      this.loopTimer = null
    }

    this.isRunning = false
  }
}
