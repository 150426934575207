import global from '@xt/client/store/global'
import SharedSelf from './shared-self'
import SharedTabInstance from './shared-tab'
import SharedWorkerInstance from './shared-worker'
import { ClientInstance, Messages } from './types'
import { reportEntryLog } from '@xt/client/utils/log'

type DebugTaskType = 'SharedWorker' | 'BroadcastChannel' | 'LowLevelBrowser'

class SharedWork {
  private isClientSupportSharedWorker: boolean
  private isClientSupportBroadcastChannel: boolean
  private pageCallbackList: Array<any>
  private sharedClient: ClientInstance
  private channelName = '__private__xt__shared__channel__'
  private tid: string

  constructor() {
    this.tid = sessionStorage.getItem('tab-id')

    this.isClientSupportSharedWorker = 'SharedWorker' in window
    this.isClientSupportBroadcastChannel = 'BroadcastChannel' in window

    /**
     * 后门 可以强制用户使用方案1-3 开始
     *
     * localStorage: `@debug-task-type` = `SharedWorker|BroadcastChannel|LowLevelBrowser`
     */
    const forceUse = localStorage.getItem('@debug-task-type') as null | DebugTaskType

    if (forceUse === 'SharedWorker' && this.isClientSupportSharedWorker) {
      this.isClientSupportSharedWorker = true
      this.isClientSupportBroadcastChannel = false
    }

    if (forceUse === 'BroadcastChannel' && this.isClientSupportBroadcastChannel) {
      this.isClientSupportSharedWorker = false
      this.isClientSupportBroadcastChannel = true
    }

    // 模拟低版本浏览器 两个都不支持
    if (forceUse === 'LowLevelBrowser') {
      this.isClientSupportSharedWorker = false
      this.isClientSupportBroadcastChannel = false
    }

    /**
     * ---- 后门结束 ----
     */

    this.pageCallbackList = []

    this.sendMessageToPage = this.sendMessageToPage.bind(this)

    // BUG iOS16.1.1用户死循环 咱不知道原因 这里先排除掉这部分逻辑
    if (global.platform === 'Mobile' && global.isEmbeddedH5Page) {
      reportEntryLog('debug.shared.embedded', {
        sharedWorker: this.isClientSupportSharedWorker,
        broadcastChannel: this.isClientSupportBroadcastChannel,
        tid: this.tid
      })
      return
    }

    /**
     * 根据当前客户端的支持情况初始化一个实例
     *
     * SharedWorker: 创建一个sharedWorker，各个浏览器页面监听sharedWorker里的事件
     * SharedTab: 由浏览器中的任意一个tab来处理任务并分发给每个页面
     * SharedSelf: 低版本浏览器既不支持SharedWorker又不支持BroadcastChannel，那就没开一个tab页面就开启一个任务
     */
    this.init()
  }

  private init() {
    const apiTarget =
      global.DEPLOY_ENV === 'dev'
        ? global.platform === 'Mobile'
          ? 'http://localhost:17001/devTarget'
          : 'http://localhost:17002/devTarget'
        : global.target
    if (this.isClientSupportSharedWorker && !global.WechatBowser) {
      console.log('当前浏览器支持SharedWorker')
      this.sharedClient = new SharedWorkerInstance({ tid: this.tid, onMessage: this.sendMessageToPage, apiTarget })
    } else if (this.isClientSupportBroadcastChannel && !global.WechatBowser) {
      console.log('当前浏览器支持BroadcastChannel')
      this.sharedClient = new SharedTabInstance({
        channelName: this.channelName,
        tid: this.tid,
        onMessage: this.sendMessageToPage,
        apiTarget
      })
    } else {
      console.log('当前是低版本浏览器或微信浏览器')
      this.sharedClient = new SharedSelf({ tid: this.tid, onMessage: this.sendMessageToPage, apiTarget })
    }
  }

  /**
   * 向所有页面发送消息
   */
  private sendMessageToPage(message: Messages) {
    this.pageCallbackList.forEach(callback => {
      // console.warn('sendMessageToPage', message)
      callback(message)
    })
  }

  public addMessageListener(callback: (message: Messages) => void) {
    this.pageCallbackList.push(callback)
  }

  public removeMessageListener(callback: (message: Messages) => void) {
    this.pageCallbackList = this.pageCallbackList.filter(_callback => _callback !== callback)
  }
}

const sharedWork = new SharedWork()

export default sharedWork
