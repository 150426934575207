







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'

@Observer
@Component({
  components: {
    HeaderImage
  }
})
export default class PlaybackPageView extends Vue {
  visibleQARecord = false

  get PageStore() {
    return this.$store.$genseePlayback
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  created() {}
  mounted() {}
  destroyed() {}
}
