import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { EnumApiCoupon } from '@xt/client/api/coupon'
import { BaseCouponItem } from '@xt/client/types/coupon'

@BindAll()
export class ControllerCouponAssocOrder extends EntitiesBasics {
  constructor($ajax: AjaxBasics) {
    super($ajax, {})
  }

  /**
   * 通过订单编号查询关联优惠券列表
   * @param orderNo
   */
  async onGetCouponListByOrderNo(orderNo: string) {
    try {
      const resp = await this.$ajax.get<Array<BaseCouponItem>>(EnumApiCoupon.CouponAssocByOrder, { sendOrderNo: orderNo })
      return resp
    } catch (error) {}
  }
}
export default ControllerCouponAssocOrder
