

































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import { PublicChat } from '@xt/client/entities/gensee/types'

@Observer
@Component({
  components: {
    HeaderImage
  }
})
export default class LivePageView extends Vue {
  ob: MutationObserver | null = null

  get PageStore() {
    return this.$store.$genseeLive
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  @Ref('chat-container')
  chatContainer: HTMLDivElement

  atChatHandler(chatMessage: PublicChat) {
    this.PageStore.appendMessageToAtList(chatMessage)
  }

  created() {}
  mounted() {
    this.$nextTick(() => {
      this.ob = new MutationObserver(list => {
        list.forEach(item => {
          if (item.addedNodes.length > 0) {
            ;(item.addedNodes[0] as HTMLDivElement).scrollIntoView({ block: 'end', behavior: 'smooth' })
          }
        })
      })
      this.ob.observe(this.chatContainer, { childList: true, attributes: false })
    })
  }
  destroyed() {
    if (this.ob) {
      this.ob.disconnect()
    }
  }
}
