

























import { Component, Prop, Vue, Provide, Inject, Mixins } from 'vue-property-decorator'
import { MixinsCourseCard } from '@xt/client/mixins'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: { LazyImg }
})
export default class CompositionPage extends Mixins(MixinsCourseCard) {}
