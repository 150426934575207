import { Component, Prop, Vue, Provide, Inject, Watch, Emit } from 'vue-property-decorator'
import { CouponItem as CouponItemType, CouponInfoListItem } from '@xt/client/types/coupon'
import { EnumCouponType, EnumCouponValidTimeType, EnumPreferentialType, IS_COMPOSE_COPPER, IS_COMPOSE_COUPON } from '@xt/client/enums/order'

@Component
export class MixinsCouponItem extends Vue {
  @Prop({ required: true }) data: CouponItemType
  @Prop({ default: true }) clickable: boolean
  @Prop({ default: false }) disabled: boolean
  // 时间类型，如果是activity则需要按照validType判断相对时间还是绝对时间，如果是coupon则可以直接取validStartTime｜validEndTime
  @Prop({ default: 'activity' }) timeType: 'activity' | 'coupon'

  // 券是否可以和别的券叠加
  get canUseWithAnotherCoupon(): boolean {
    return !!(this.data.composeFlag & IS_COMPOSE_COUPON)
  }

  // 使用本张券后是否可以使用铜板
  get canUseWithCopper(): boolean {
    return !!(this.data.composeFlag & IS_COMPOSE_COPPER)
  }

  // 是否是折扣券
  get isDiscountCoupon(): boolean {
    return this.data.preferentialType === EnumPreferentialType.Discount
  }

  // 当前券是否是
  get isRelativeTimeCoupon(): boolean {
    return this.timeType === 'activity' && this.data.validType === EnumCouponValidTimeType.Relative
  }

  // 是否是满减券
  get isFullReductionCoupon(): boolean {
    return this.data.preferentialType === EnumPreferentialType.FullReduction
  }

  get couponExpiredTime(): string {
    if (this.isRelativeTimeCoupon) return ''

    return this.moment(this.data.validEndTime).utcOffset(+8).format('YYYY.MM.DD HH:mm')
  }

  get couponStartTime(): string {
    if (this.isRelativeTimeCoupon) return ''

    return this.moment(this.data.validStartTime).utcOffset(+8).format('YYYY.MM.DD HH:mm')
  }

  get relativeCouponExpiredDay(): number {
    if (this.isRelativeTimeCoupon) {
      return this.data.validTimeDuration / 60 / 60 / 24
    }

    return 0
  }

  get couponTip(): string {
    const result: Array<string> = []

    if (this.data.couponType === EnumCouponType.Discount || this.data.couponType === EnumCouponType.FullReduction) {
      if (this.data.allowGive === false) {
        result.push('限自买指定课程使用')
      }

      if (this.data.allowGiveUse === true) {
        result.push('限购买指定课程使用')
      }

      if (!this.canUseWithCopper) {
        result.push('桐板儿不可用')
      }
    } else if (this.data.couponType === EnumCouponType.Exchange) {
      result.push('限兑换指定课程使用')
    } else if (`${this.data.couponType}`.startsWith('2')) {
      result.push('限暄桐文房微信店使用')
    } else if (`${this.data.couponType}`.startsWith('3')) {
      result.push('限学习指定课程使用')
    }

    return result.join('，')
  }

  // 券使用说明
  get couponInfoList(): Array<CouponInfoListItem> {
    const result: Array<CouponInfoListItem> = []

    // 券时间
    result.push({
      icon: 'iconcoupon_time',
      title: '使用时间',
      value: this.isRelativeTimeCoupon
        ? `获得后${this.relativeCouponExpiredDay}天内可用`
        : `${this.couponStartTime}-${this.couponExpiredTime}`
    })

    // 适用课程
    if (Array.isArray(this.toJS(this.data.exchangeProducts)) && this.data.exchangeProducts.length > 0) {
      result.push({
        icon: 'iconcoupon_course',
        title: '适用课程',
        value: this.data.exchangeProducts.map(i => i.productName).join('、')
      })
    }

    // 适用范围
    if (typeof this.data.allowGiveUse === 'boolean') {
      result.push({
        icon: 'iconcoupon_scope',
        title: '适用范围',
        value: this.data.allowGive ? '自买' : '自买、赠课'
      })
    }

    // 使用限制
    const limit: string[] = []
    if (this.data.couponType === EnumCouponType.FullReduction || this.data.couponType === EnumCouponType.Discount) {
      // 满减、折扣券
      if (this.data.allowGive === false) {
        limit.push('限自买指定课程使用')
      }

      if (this.data.allowGiveUse === true) {
        limit.push('限购买指定课程使用')
      }

      if (this.canUseWithAnotherCoupon) {
        limit.push('可与1张非叠加券叠加使用')
      }

      if (!this.canUseWithCopper) {
        limit.push('铜板儿不可同时使用')
      }

      if (this.data.couponType === EnumCouponType.Discount) {
        limit.push(`最高可抵扣${this.data.limitAmount / 100}元`)
      }

      if (this.data.allowGive) {
        limit.push('券可转赠Ta人')
      }
    } else if (this.data.couponType === EnumCouponType.PencilStore) {
      // 文房券
      limit.push(`限暄桐文房微信店使用`)
    } else if (this.data.couponType === EnumCouponType.Exchange) {
      // 兑换券
      limit.push('限兑换指定课程使用')
    } else if (this.data.couponType === EnumCouponType.Companion) {
      // 伴学卡
      limit.push('限学习指定课程使用')
    }

    if (limit.length > 0) {
      result.push({
        icon: 'iconcoupon_limit',
        title: '使用限制',
        value: limit.join('，')
      })
    }

    return result
  }

  @Emit('click-use-info')
  handleClickUseInfo() {
    return {
      list: this.couponInfoList,
      coupon: this.data
    }
  }

  handleClick() {
    if (!this.disabled) {
      this.$emit('click', this.data.id)
    }
  }
}
