/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-09-25 16:02:31
 * @modify date 2020-09-25 16:02:31
 * @desc 课程详情
 */
/// <reference types="./course" />
import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { action, observable, runInAction, toJS } from 'mobx'
import { EnumApiCourse, EnumApiProduct } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
// import { onComputeState } from "./helpers"
import { CourseMap } from './map'
import { CourseThoughts } from './thoughts'
import { ClassHourLive } from './classhour'
import { Course } from '../basics/course'
import { EnumProductType } from '../../enums'
import { AjaxRequest } from 'rxjs/ajax'
import $global from '../../store/global'

type GiveawayInfo = {
  ids: Array<{
    courseProductId: number
    giveawayProductId: number
  }>
  coverUrl: string
  courseType: string
  courseName: string
  courseStudyText: string
  courseIntroduceTip: string
  courseIntroduceList: string[]
  courseIntroducePerson: string
  courseStudyQRCode: string
  courseScanQRCodeTip: string
  giveawayTipH5: string
}

type StudentWelfareInfo = {
  productIds: number[]
}

/**
 * 课程详情
 */
@BindAll()
export class CourseDetails extends EntitiesBasics<Course> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})
    this.onReset({
      // 课程详情地址
      url: EnumApiCourse.CourseDetails
    })
  }
  async onLoading(body?: any, AjaxRequest?: AjaxRequest): Promise<any> {
    let res: any = await this.$ajax.get(EnumApiCourse.CourseDetails, body)
    let productType: any = ''
    //单节课
    if (res.courseType == 1) {
      productType = EnumProductType.single
    }
    //全阶课
    if (res.courseType == 2) {
      productType = EnumProductType.fullstage
    }
    const product: any = await this.$ajax.get(EnumApiProduct.productDetail, {
      bizId: res.id,
      productType
    })
    //增加商品信息
    res.productInfo = product
    let dataSource = new Course(res)
    return this.onSetDataSource(dataSource)
  }
  /**
   * 获取直播课详情
   * @param id
   * @returns
   */
  async getCourseDetail(id: number): Promise<any> {
    const res = await this.$ajax.get(EnumApiCourse.CourseDetails, { id })
    return res
  }
  /**
   * 订单信息 课程
   * @memberof CourseDetails
   */
  @observable Orderinfo: Course = null
  async onOrderinfo(id, judgeHasOwn?) {
    try {
      const res = await this.$ajax.get(EnumApiProduct.productDetailByProductId, {
        productId: id,
        judgeHasOwn: judgeHasOwn ? 'true' : 'false'
      })
      // return false;
      const orderinfo = new Course(res)
      // orderinfo.courseSingleList = lodash.map(orderinfo.singleList, onComputeState)
      // lodash.unset(orderinfo, 'singleList')
      runInAction(() => {
        this.Orderinfo = orderinfo
      })

      return orderinfo
    } catch (error) {}
  }

  /**
   * 获取我购买过的课程
   */
  async onOwnHasbuyCourse() {
    try {
      const res: Array<number> = await this.$ajax.get(EnumApiCourse.CourseListHasbuy)
      // 自己买过的课程
      const hasbuyCourse: Array<any> = await this.$ajax.get(EnumApiProduct.productHasBuy)
      Course.onSetUserHasbuy(res, hasbuyCourse)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 计算课程是否购买
   * @param hasbuy
   */
  @action
  onHasOwnOrderinfo(hasbuy, isGive?) {
    this.Orderinfo = new Course(this.Orderinfo.original, hasbuy, false, isGive == true ? true : false)
  }

  @observable giveawayInfo: GiveawayInfo | null = null
  /** 获取赠品相关信息 */
  async onGetGiveawayInfo() {
    try {
      this.giveawayInfo = await this.$ajax.get<GiveawayInfo>(
        '/mocks/giveaway.json',
        {},
        { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
        {
          target: $global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
        }
      )
    } catch (error) {}
  }

  @observable studentWelfareInfo: StudentWelfareInfo | null = null
  /** 获取老桐学优惠相关信息 */
  async onGetStudentWelfareInfo() {
    try {
      this.studentWelfareInfo = await this.$ajax.get<StudentWelfareInfo>(
        '/mocks/welfare.json',
        {},
        { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
        {
          target: $global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
        }
      )
    } catch (error) {}
  }
  /**
   * 获取用户 已购买课程 id
   * @param id
   * @param memberId
   */
  // onGetHasbuy(id, memberId) {
  //   return this.$ajax.get(EnumApiCourse.CouseHasbuy, { id, memberId })
  // }
  /**
   * 地图
   * @memberof ControllerCourse
   */
  Map = new CourseMap(this.$ajax)
  /**
   * 感想
   * @memberof CourseDetails
   */
  Thoughts = new CourseThoughts(this.$ajax)

  /**
   * 课时直播
   */
  ClasshourLive = new ClassHourLive(this.$ajax)
}
export default CourseDetails
