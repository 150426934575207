import { ClientInstance } from './types'
import { SharedWorkerMessage } from './consts'
import { reportEntryLog } from '@xt/client/utils/log'

export default class SharedWorkerInstance implements ClientInstance {
  private worker: SharedWorker
  private tid: string
  private onMessage: (message: any) => void
  private apiTarget: string

  constructor(options: { tid: string; onMessage: (message: any) => void; apiTarget: string }) {
    this.apiTarget = options.apiTarget
    this.tid = options.tid
    this.onMessage = options.onMessage
    this.worker = new SharedWorker(`/assets/workers/xt-shared.worker.js?v=0.0.5`, 'xt-shared')
    this.worker.onerror = e => {
      console.error('SharedWorker创建失败', e)
      reportEntryLog('shared-worker.create.fail', { e })
    }

    this.worker.port.onmessage = e => {
      // console.log('收到了sharedwork的消息', e.data)
      this.onMessage(e.data)
    }

    this.worker.port.postMessage({
      type: SharedWorkerMessage.Connect,
      payload: {
        tid: this.tid,
        apiTarget: this.apiTarget
      }
    })

    window.addEventListener('beforeunload', () => {
      this.worker.port.postMessage({
        type: SharedWorkerMessage.Disconnect,
        payload: {
          tid: this.tid
        }
      })
    })
  }

  public async sendMessage(): Promise<[boolean, null]> {
    return [true, null]
  }
}
