












import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import BiShan from './item/bishan.vue'
import Coin from './item/coin.vue'
import { MixinsMyPoints } from '@xt/client/mixins'

@Observer
@Component({
  scrollToTop: true,
  components: { BiShan, Coin }
})
export default class PageView extends Mixins(MixinsMyPoints) {
  get showState() {
    return lodash.get(this.$route.query, 'active') as 'bishan' | 'coin'
  }
  defaultActiveKey: any = 0
  tabsChange(e) {}
  goToPoints() {
    this.$router.push({
      name: 'my-points'
    })
  }
  created() {}
}
