import jwtDecode, { JwtPayload } from 'jwt-decode'
import lodash from 'lodash'
import { action, autorun, computed, observable, runInAction, toJS } from 'mobx'
import moment from 'moment'
import { gotoPractice } from '../../helpers/gotoPractice'
import $global from '../../store/global'
import { BackupKeys } from './consts'
import { AjaxBasics } from '@xt/client/helpers'

type LogoutType = 'notice' | 'condition' | 'auth' | 'account' | 'abandon'
type AbandonType = 'course' | 'resource'

declare var zc: any
export default class EntitiesUser {
  constructor() {
    autorun(() => {
      const redirectBackUrl = sessionStorage.getItem('__redirectBackUrl')
      if (this.loggedIn && redirectBackUrl) {
        sessionStorage.removeItem('__redirectBackUrl')
        const url = new URL(redirectBackUrl)
        gotoPractice({
          url: url.toString(),
          tokens: this.UserInfo
        })
      }
    })
  }
  /**
   * 加载状态
   * @memberof Pagination
   */
  @observable
  loading = false
  /**
   * 显示登录框
   * @memberof Pagination
   */
  @observable
  visible = false

  /**
   * 在PC登录弹窗上层覆盖一个loading蒙层
   * 当前用户拖动验证码成功后立刻关闭并异步调用接口，防止重复调用接口
   * @type {boolean}
   */
  @observable
  isLoginModalLoading = false

  /**
   * 用来阻止登录框弹出，检测一次后会重置为false
   */
  @observable
  preventVisible = false

  /**
   * 登录框展示内容
   * @memberof Pagination
   */
  @observable
  ModalTitle = ''
  /**
   * 登录框状态 phone 手机验证 email 邮箱验证 noBackPhone 不可返回的手机验证 noBackEmail 不可返回的邮箱验证
   * @memberof Pagination
   */
  @observable
  ModalState = 'phone'

  /**
   * 用户信息
   * @memberof EntitiesStationery
   */
  @observable
  UserInfo: any = {}

  /**
   * 用户的收货地址
   */
  @observable
  userAddress: {
    id: string | null
    city: string | null
    detailAddress: string | null
    district: string | null
    nickname: string | null
    phoneNum: string | null
    postalCode: string | null
    province: string | null
    street: string | null
  } | null = null

  @observable
  repage: string

  @action
  public usedRepage(href: string) {
    let repage = this.repage || href
    this.repage = null
    return repage
  }

  @action.bound
  setWXCheckTime(time: number) {
    this.UserInfo.wxCheckTime = time
  }

  @action.bound
  setWXNickname(value: string) {
    this.UserInfo.wxNickname = value
  }

  @action.bound
  setUserInfo(info) {
    // 记录登录时间点
    if (info.access_token) {
      info.login_time = Date.now()
      // info.expires_in = 10000
    }
    this.UserInfo = info

    if ($global.platform === 'Mobile') {
      switch (sessionStorage.getItem('__xt__embedded__source')) {
        case 'uc':
          localStorage.setItem(BackupKeys.user_uc, JSON.stringify({ UserInfo: info }))
          break
        case 'show':
          localStorage.setItem(BackupKeys.user_show, JSON.stringify({ UserInfo: info }))
          break
        default:
          localStorage.setItem(BackupKeys.user_h5, JSON.stringify({ UserInfo: info }))
          break
      }
    }
  }
  /**
   * 设置智齿客服的用户信息
   * @param info
   */
  protected setZhichiUser(info) {
    if (!window['zc']) return
    zc('config', {
      partnerid: info.id, //客户id
      uname: info.nickname, //客户名称
      realname: info.nickname, //客户真实姓名
      tel: info.phoneNum, //客户电话,
      email: info.email, //客户邮箱
      face: info.headerUrl, //客户头像
      remark: '用户已登录' //客户备注信息
    })
    window.onmessage = e => {
      if (e && e.data) {
        // 此处JSON.parse可能会有问题
        // Unexpected token 's', "setImmedia"... is not valid JSON
        try {
          const { name, data } = lodash.isString(e.data) ? JSON.parse(e.data) : e.data
          if (name === 'zc_post_message' && data.action === 'chat_collapse_window') {
            zc('config', {
              refresh: false // 每次打开都刷新
            })
            window.onmessage = () => {}
          }
        } catch {}
      }
    }
  }

  @computed
  get isWxBind() {
    return !lodash.isEmpty(lodash.get(this.UserInfo, 'wxNickname'))
  }

  @computed
  get wxCheckTime() {
    return lodash.get(this.UserInfo, 'wxCheckTime')
  }

  /**
   * 设置 微信 wxOpenid
   * @param wxOpenid
   */
  @action.bound
  setWXOpenid(wxOpenid) {
    this.UserInfo = lodash.merge({ wxOpenid }, toJS(this.UserInfo))
  }
  /**
   * 已购买 的课程 ids
   * @type {Array<any>}
   * @memberof EntitiesUser
   */
  @observable CourseListHasbuy: Array<any> = []
  @observable CourseGroupHasbuy: Array<any> = []

  /**
   * 我的礼物列表
   * @type {Array<any>}
   * @memberof EntitiesUser
   */
  @observable OrderGifts: Array<any> = []
  @observable OrderGiftsData: Array<any> = []

  @action.bound
  protected setCourseListHasbuy(list, groupHasBuy) {
    this.CourseListHasbuy = list
    this.CourseGroupHasbuy = groupHasBuy
  }

  @action.bound
  protected setOrderGifts(list) {
    this.OrderGifts = [...list]
  }

  @action
  removeOrderGifts(id) {
    const OrderGifts = [...this.OrderGifts]
    lodash.remove(OrderGifts, item => item.id == id)
    this.setOrderGifts(OrderGifts)
  }

  @action
  removeAllOrderGifts() {
    this.setOrderGifts([])
  }

  @action.bound
  protected setOrderGiftsData(list) {
    this.OrderGiftsData = [...list]
  }

  @action
  removeOrderGiftsData(id) {
    const OrderGiftsData = [...this.OrderGiftsData]
    lodash.remove(OrderGiftsData, item => item.id === id)
    this.setOrderGiftsData(OrderGiftsData)
  }

  @observable claimedGift: string[] = []

  @action.bound
  protected setClaimedGift(list) {
    this.claimedGift = [...list]
  }

  @observable uniformGift: Array<any> = []
  @observable uniformGiftData: Array<any> = []

  @action.bound
  protected setUniformGift(list) {
    this.uniformGift = [...list]
  }

  @action
  removeUniformGift() {
    this.uniformGift.shift()
  }

  @action.bound
  protected setUniformGiftData(list) {
    this.uniformGiftData = [...list]
  }

  @action
  removeUniformGiftData() {
    this.uniformGiftData.shift()
  }

  /**
   * 微信 openid
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get wxOpenid() {
    return this.UserInfo.wxOpenid
  }
  /**
   * 桐板儿
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get copper() {
    return this.UserInfo.coin
  }
  /**
   * 登录状态
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get loggedIn() {
    return !!this.UserInfo.access_token
  }

  isLogin() {
    return !!this.UserInfo.access_token
  }

  /**
   * 解密的 jwt 信息
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get JwtPayload() {
    if (this.loggedIn) {
      return jwtDecode<
        JwtPayload & {
          authorities: Array<String>
          client_id: string
          user_name: string
          auth_type: string
        }
      >(this.UserInfo.access_token)
    }
  }
  /**
   * 过期时间
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get expirationTime() {
    const exp = lodash.get(this.JwtPayload, 'exp', 0)
    if (exp) {
      return moment(exp * 1000)
    }
    return AjaxBasics.serviceDate.utcOffset(+8)
  }
  /**
   * 特殊协议
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get visibleSpecialAgreement() {
    return this.UserInfo.specialProtocolId === 0
  }

  /**
   * 是否需要绑定设备
   * @readonly
   * @memberof EntitiesUser
   */
  @computed
  get needBindDevice() {
    return !lodash.get(this.UserInfo, 'email') && !lodash.get(this.UserInfo, 'phoneNum')
  }

  /**
   * 等级
   */
  @computed
  get level() {
    return this.UserInfo.level || 0
  }
  /**
   * 精进值
   */
  @computed
  get count() {
    return this.UserInfo.count || 0
  }
  minWidth: number = 15
  maxWidth: number = 340
  @observable progress: number = 0
  interval: any
  @observable pathD: string = ''
  sumexperience = [0, 200, 450, 800, 1300, 2000, 3000, 4500, 7000, 12500, 22500]
  upgrade = [0, 200, 250, 350, 500, 700, 1000, 1500, 2500, 5500, 10000]
  /** 经验 */
  @action
  experience() {
    let distance: number = ((this.progress > 1 && 1) || this.progress) * this.maxWidth
    // 初始进度，最小进度
    let maxHeight = 10 + 10 * ((this.progress > 1 && 1) || this.progress)
    // 右边半圆的半径
    let rightRadius = maxHeight / 2
    // 右上的X坐标
    let topRightX = distance - rightRadius
    // 右上的Y坐标
    let topRightY = 20 - maxHeight
    // 重新计算路径
    this.pathD = `M 5 10 L ${topRightX} ${topRightY} A ${rightRadius} ${rightRadius} 0 1 1 ${topRightX} 20 L ${
      topRightX / 2
    } 20 L 5 20 A 5 5 0 1 1 5 10 Z`
  }

  /**
   * 是否需要完善个人信息
   * @readonly
   * @memberof EntitiesUser
   */
  // @computed
  onneedCompleteInfo() {
    let info = lodash.pick(this.UserInfo, [
      'sex', //性别(0 未知 1 男 2女)
      'birthday', //生日
      'industry', //行业
      'career', //职业
      'education', //学历
      'location' //国家
    ])
    return lodash.some(lodash.values(info), item => {
      return lodash.includes(['', 0, null, undefined], item)
    })
    // return true
  }

  /**
   * 切换加载状态
   * @private
   * @param {boolean} [loading=!this.loading]
   * @memberof Pagination
   */
  @action
  protected onToggleLoading(loading: boolean = !this.loading) {
    this.loading = loading
  }
  /**
   * 切换登录框显示
   * @param visible
   */
  @action
  onToggleVisible(visible: boolean = !this.visible, repage: string = null) {
    if (visible && this.loggedIn) {
      return
    }
    this.repage = repage
    if (visible && this.preventVisible) {
      this.preventVisible = false
    } else {
      this.visible = visible
    }

    // h5登录的popup组件有0.3秒的动画
    setTimeout(
      () => {
        runInAction(() => {
          this.ModalTitle = 'links_signin'
        })
      },
      $global.platform === 'Mobile' ? 500 : 0
    )
  }

  @action
  preventVisibleOnce() {
    this.preventVisible = true
  }

  /**
   * 切换登录框功能
   * @param title 登录框功能标题
   * @param state 状态
   */
  @action
  onToggleModal(title, state: 'phone' | 'email' | 'noBackPhone' | 'noBackEmail' = 'phone') {
    this.visible = true
    this.ModalTitle = title
    this.ModalState = state
  }

  /** 注销弹窗展示不展示 */
  @observable logoutVisible: boolean = false
  /** 注销弹窗里面展示的内容 */
  @observable logoutModalTitle: LogoutType = 'notice'
  /** 放弃的类型  放弃资源 ｜ 放弃课程 */
  @observable abandonChoose: AbandonType = 'course'
  /** 是否点击了放弃课程 */
  @observable clickAbandonCourse: boolean = false
  /** 是否点击了放弃虚拟资源 */
  @observable clickAbandonResource: boolean = false

  /**
   * 切换注销弹窗展示不展示
   * @param visible
   * @returns
   */
  @action
  onToggleLogoutVisible(visible: boolean) {
    this.logoutVisible = visible
    this.logoutModalTitle = 'notice'
    this.abandonChoose = 'course'
    this.onResetLogoutInfo()
  }

  /**
   * 切换弹窗里面的内容
   * @param title
   */
  @action
  onToggleLogoutModal(title: LogoutType) {
    this.logoutVisible = true
    this.logoutModalTitle = title
  }

  @action
  onAgreeAbandonChoose(choose: AbandonType) {
    if (choose === 'course') this.clickAbandonCourse = true
    else this.clickAbandonResource = true
  }

  @action
  onChangeAbandonChoose(choose: AbandonType) {
    this.abandonChoose = choose
  }

  @action
  onResetLogoutInfo() {
    this.clickAbandonCourse = false
    this.clickAbandonResource = false
  }

  @action
  onChangeUserAddress(addressInfo) {
    this.userAddress = addressInfo
  }

  @action
  onChangeLoginModalLoading(loading: boolean) {
    this.isLoginModalLoading = loading
  }

  /**
   * 完善个人信息后跳转支付页面数据
   */
  // @observable
  // toPayMentQuery = {}

  // /**
  //  * 设置完善个人信息后跳转支付页面的数据
  //  */
  // @action
  // onSetToPaymentQuery(data) {
  //   this.toPayMentQuery = data
  // }
}
