

















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'
import { reportEntryLog } from '@xt/client/utils/log'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop() error
  get status() {
    if (this.error.statusCode === 200) {
      return '500'
    }
    return String(this.error.statusCode)
  }
  get homePage() {
    return ['index'].includes(this.$route.name?.replace(/-(index)/g, ''))
  }
  goHome() {
    if (this.homePage) {
      window.location.reload()
    } else {
      this.$router.replace({
        path: '/'
      })
    }
  }
  get production() {
    return this.$store.$global.production
  }
  get path() {
    return this.$route.path
  }
  get query() {
    return this.$route.query
  }
  get message() {
    if (this.production && parseInt(this.status, 10) >= 500) {
      return '数据异常，请联系客服'
    }
    if (this.status === '404') {
      return '找不到页面'
    }
    // 处理499 和 405错误
    if (this.status === '405') {
      return '数据异常，请联系客服'
    }
    if (this.status === '499') {
      return '服务端繁忙，请刷新试试吧'
    }
    return this.error.message
  }
  redirect = false
  created() {
    console.log('LENG: extends -> created -> this', this)
    // 上报错误日志
    reportEntryLog('app.layout.error', {
      path: this.path,
      query: this.query,
      message: this.message,
      status: this.status,
      error: this.error
    })
    if (this.status === '404') {
      if (lodash.has(this.query, 'redirect')) {
        return
      }
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
