























































import { Component, Prop, Vue, Provide, Inject, Mixins } from 'vue-property-decorator'
import SlidePop from './SlidePop.vue'
import CouponItem from './CouponItem.vue'
import CouponUseInfo from './CouponUseInfo.vue'
import { CouponInfoListItem, BaseCouponItem } from '@xt/client/types/coupon'

@Component({
  components: { SlidePop, CouponItem, CouponUseInfo }
})
export default class WelfareCouponSelectPop extends Vue {
  visible: boolean = false
  activeKey: 0 | 1 = 0
  slide: 0 | 1 = 0

  secondViewInfo: {
    list: CouponInfoListItem[]
    coupon: BaseCouponItem | null
  } = {
    list: [],
    coupon: null
  }

  get CouponStore() {
    return this.$store.$coupon
  }

  show() {
    this.CouponStore.rearrangeOrderCoupons()
    this.CouponStore.backupAvailableCouponList()
    this.visible = true
  }

  hide(restore = true) {
    this.visible = false
    this.slide = 0

    if (restore) {
      this.CouponStore.rollbackCurrentAvailableCouponSelect()
    }
  }

  handleConfirm() {
    this.hide(false)
    // 计算出当前最新的优惠券抵扣金额和是否可以继续使用铜板
    const dicount = this.CouponStore.userSelectCouponDiscount
    const useWithCopper = this.CouponStore.useWithCopper
    const coupondIds = this.CouponStore.userSelectCouponIds
    this.$emit('confirm-select', { dicount, useWithCopper, coupondIds })
  }

  handleSelectAvailableCouponItem(couponId: number) {
    this.CouponStore.onSelectCoupon(couponId)
  }

  handleClickUseInfo(info: { list: CouponInfoListItem[]; coupon: BaseCouponItem }) {
    this.secondViewInfo = {
      list: info.list,
      coupon: info.coupon
    }
    this.slide = 1
  }
}
