export enum SharedWorkerMessage {
  /**
   * 新页面连接到SharedWorker
   */
  Connect = 'Connect',
  /**
   * 有页面关闭
   */
  Disconnect = 'Disconnect',
  /**
   * 服务端时间 ResponseHeader Date
   */
  SyncServerDate = 'SyncServerDate',
  /**
   * SharedWorker内部事件
   */
  WorkerMessage = 'WorkerMessage'
}

export enum SharedTabMessage {
  /**
   * Tab在处理任务
   */
  TabRunTask = 'TabRunTask',
  /**
   * Tab不在处理任务
   */
  TabPauseTask = 'TabPauseTask',
  /**
   * 服务端时间 ResponseHeader Date
   */
  SyncServerDate = 'SyncServerDate'
}

export enum SharedSelfMessage {
  /**
   * 服务端时间 ResponseHeader Date
   */
  SyncServerDate = 'SyncServerDate'
}
