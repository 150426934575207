








import { Component, Prop, Vue, Provide, Inject, Mixins } from 'vue-property-decorator'
import { CouponInfoListItem, BaseCouponItem } from '@xt/client/types/coupon'
import SlidePop from '@xt/www/components/coupon/SlidePop.vue'
import CouponUseInfo from '@xt/www/components/coupon/CouponUseInfo.vue'

@Component({
  components: { SlidePop, CouponUseInfo }
})
export default class SelectCouponInfoPop extends Vue {
  @Prop({ default: false }) show: boolean

  @Prop({ required: true }) data: {
    list: CouponInfoListItem[]
    coupon: BaseCouponItem | null
  }
}
