import { BindAll } from 'lodash-decorators'
import { action, observable, toJS } from 'mobx'
import lodash from 'lodash'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { chainLoadScript } from './utils'
import { GenseeBase, Channel, ChatItem, ChapterItem, QAItem, QAItemParsed, Rate } from './types.d'
import moment from 'moment'
import global from '@xt/client/store/global'
import { EnumApiUser } from '@xt/client/api'
import socketClient from '@xt/client/utils/socket'
import { Loop } from '@xt/client/utils/loop'
import { reportEntryLog } from '@xt/client/utils/log'
import { VideoLog } from './log'

declare const GS: GenseeBase

export type VideoState = {
  /**
   * API 是否初始化完毕
   */
  isReady: boolean
  /**
   * 视频是否正在播放中
   */
  isPlaying: boolean
  /**
   * 视频总播放时长，毫秒时间戳
   */
  duration: number
  /**
   * 当前播放进度，毫秒时间戳
   */
  current: number
  /**
   * 进度条，0 - 100
   */
  progress: number
  /**
   * 当前播放速率
   */
  rate: number
  /**
   * 当前音量
   */
  volume: number
  /**
   * 是否静音
   */
  mute: boolean
  /**
   * 授权打开声音播放视频
   */
  authOpenSound: boolean
  /**
   * 是否全屏
   */
  isFullScreen: boolean
}

export type WidgetParams = {
  ownerid: string
  uid: string
  uname: string
  k: string
}

@BindAll()
export class ControllerGenseePlayback extends EntitiesBasics {
  private channel: Channel
  private pollingLoop: Loop
  isDragProgress = false

  @observable isLoading = true
  skeletonLoadingStart = 0

  // 当前账号的用户在不同浏览器上观看直播或回放 返回首页 此时账号以及被登出
  @observable isWatchOnOtherBrowser = false
  // 当前账号的用户在相同浏览器上观看直播或回放 返回首页 不登出
  @observable isWatchOnSameBrowser = false

  // 展示互动组件上的参数
  @observable widgetParams: WidgetParams = {
    ownerid: '',
    uid: '',
    uname: '',
    k: ''
  }

  @observable videoState: VideoState = {
    isReady: false,
    isPlaying: false,
    duration: 0,
    current: 0,
    progress: 0,
    rate: 1,
    volume: 75,
    mute: false,
    authOpenSound: false,
    isFullScreen: false
  }

  /**
   * 聊天历史数据
   */
  @observable chatMessageList: Array<ChatItem> = []

  /**
   * doc 章节数据
   */
  @observable chapterList: Array<ChapterItem> = []

  /**
   * qa 列表原始数据，原始数据结构不方便视图。格式化为qaList使用
   */
  private qaListOrigin: Array<QAItem> = []

  /**
   * qa 列表
   */
  @observable qaList: Array<QAItemParsed> = []

  @observable videoRateList: Array<Rate> = [
    {
      rate: 2,
      text: '2.0倍'
    },
    {
      rate: 1.5,
      text: '1.5倍'
    },
    {
      rate: 1.25,
      text: '1.25倍'
    },
    {
      rate: 1,
      text: '正常'
    },
    {
      rate: 0.75,
      text: '0.75倍'
    },
    {
      rate: 0.5,
      text: '0.5倍'
    }
  ]

  private stateChangeListener: Array<(state: VideoState) => void> = []

  private videoPlayTimer: null | ReturnType<typeof setInterval> = null

  private openSoundTimer: NodeJS.Timer | null = null

  currentTabId: string

  // 页面10s还没有loading完增加弹窗提醒
  pageReloadTimer: null | ReturnType<typeof setTimeout>
  // 页面持续卡在loading
  @observable isPageLoadingTooLong = false

  isFirstVideoPlay = false

  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})

    this.currentTabId = sessionStorage.getItem('tab-id')
  }

  async init(groupName: string) {
    const scriptLoaded = await chainLoadScript(['/assets/jquery-1.9.1.min.js', '/assets/gssdk-1.3.js'])

    if (scriptLoaded) {
      const channel = GS.createChannel(groupName)
      this.channel = channel
      this.bindEvent()
    }
  }

  private bindEvent() {
    this.channel.bind('onDataReady', ({ data }) => {
      this.setLoading(false)
      reportEntryLog(VideoLog.playback_gensee_data_ready, { data, tabId: this.currentTabId })

      this.changeVideoState({ isReady: true })
      if (data.supportChatSync) {
        this.channel.send('setupChatSync', { open: true })
      }

      this.channel.send('submitQAList')
    })

    this.channel.bind('onFileDuration', ({ data }) => {
      this.changeVideoState({ duration: data.duration })
    })

    this.channel.bind('onPlay', () => {
      console.log('onPlay call')
      if (this.videoPlayTimer) {
        clearInterval(this.videoPlayTimer)
        this.videoPlayTimer = null
      }

      this.videoPlayTimer = setInterval(() => {
        this.channel.send('playheadTime')
      }, 250)

      this.changeVideoState({ isPlaying: true })

      if (!this.isFirstVideoPlay) {
        this.isFirstVideoPlay = true

        // 视频第一次播放
        const videoElement = document.querySelector('video')
        if (videoElement && global.platform === 'Mobile') {
          // 提示用户打开浏览器声音
          if (videoElement.muted) {
            this.changeVideoState({ authOpenSound: true })
            this.openSoundTimer = setTimeout(this.resetSoundTimer, 10000)
          }
        }
      }
    })

    this.channel.bind('onAPIError', ({ data }) => {
      console.log('onAPIError', data)
      reportEntryLog(VideoLog.playback_gensee_error, { data, tabId: this.currentTabId })
    })

    this.channel.bind('onStatus', ({ data }) => {
      console.log('onStatus', data)
      reportEntryLog(VideoLog.playback_gensee_status, { data, tabId: this.currentTabId })
    })

    this.channel.bind('onPause', () => {
      console.log('onPause call')
      if (this.videoPlayTimer) {
        clearInterval(this.videoPlayTimer)
        this.videoPlayTimer = null
      }

      this.changeVideoState({ isPlaying: false })
    })

    this.channel.bind('onStop', () => {
      console.log('onStop call')
      this.changeVideoState({ progress: 0, current: 0 })
      this.channel.send('pause')
    })

    this.channel.bind('onSeekCompleted', ({ data }) => {
      console.log('onSeekCompleted call', data)
      this.channel.send('submitChatSegment')
    })

    this.channel.bind('onPlayheadTime', ({ data }) => {
      const { progress, current } = this.getCurrentVideoTime(data.playheadTime)
      this.changeVideoState({ current })
      if (!this.isDragProgress) {
        this.changeVideoState({ progress })
      }
      const chapter = this.chapterList.map((item, index) => {
        return {
          ...item,
          isCurrent: current >= item.starttimestamp && current < item.stoptimestamp,
          title: item.title || `${index + 1}`
        }
      })
      this.setChapterList(chapter)
    })

    this.channel.bind('onChat', ({ data }) => {
      console.log('onChat', data)
      this.appendChatMessage(data.list)
    })

    this.channel.bind('onChatSegmentList', ({ data }) => {
      console.log('onChatSegmentList', data)
      this.setChatMessageList(data.list)
    })

    this.channel.bind('onQAList', ({ data }) => {
      this.qaListOrigin = this.qaListOrigin.concat(data.list)
      this.setQAList(this.parseQAList(this.qaListOrigin))
      console.log('onQAList', data, toJS(this.qaList))

      if (data.more !== false) {
        // 加载下一页数据
        this.channel.send('submitQAList')
      }
    })

    this.channel.bind('onChapter', ({ data }) => {
      const chapterList = data.list.map((chapter, index) => {
        return {
          ...chapter,
          isCurrent: this.videoState.current >= chapter.starttimestamp && this.videoState.current < chapter.stoptimestamp,
          title: chapter.title || `${index + 1}`
        }
      })
      console.log('onChapter', chapterList)
      this.setChapterList(chapterList)
    })

    this.channel.bind('onVideoClick', () => {
      console.log('onVideoClick')
      this.toggleVideoPlay()
    })

    this.channel.bind('onManualAutoplay', ({ data }) => {
      if (global.platform === 'PC') {
        console.log('onManualAutoplay', data)
        this.resetVolumePlay()
        this.changeVideoState({ authOpenSound: true })
        this.openSoundTimer = setTimeout(this.resetSoundTimer, 10000)
      }
    })

    this.channel.bind('onMute', ({ data }) => {
      console.log('onMute', data.mute)
      this.changeVideoState({ mute: data.mute })
    })
  }

  public toggleVideoPlay() {
    if (!this.videoState.isReady) return

    if (this.videoState.isPlaying) {
      this.channel.send('pause')
    } else {
      this.channel.send('play')
    }

    this.changeVideoState({ isPlaying: !this.videoState.isPlaying })
  }

  public playVideo() {
    this.channel.send('play')
    this.changeVideoState({ isPlaying: true })
  }

  public pauseVideo() {
    this.channel.send('pause')
    this.changeVideoState({ isPlaying: false })
  }

  /**
   * 通过拖动进度条来维护progress
   * 只改变值，视频正常播放，不需要做seek处理
   * @param progress 0 - 100  step：0.01
   */
  public updateProgressByDrag(progress: number) {
    this.isDragProgress = true
    this.changeVideoState({ progress })
  }

  public onProgressDragFinish(progress: number) {
    console.log('onProgressDragFinish', progress)
    this.isDragProgress = false
    if (this.videoPlayTimer) {
      clearInterval(this.videoPlayTimer)
      this.videoPlayTimer = null
    }
    this.seekVideoByProgress(progress)
  }

  resetSoundTimer() {
    if (this.openSoundTimer) {
      clearTimeout(this.openSoundTimer)
      this.openSoundTimer = null
    }
    this.changeVideoState({ authOpenSound: false })
  }

  /**
   * 通过拖动音量进度条来改变视频播放音量
   * @param volume 0 - 100  step：1
   */
  public updateVolumeByDrag(volume: number) {
    this.channel.send('submitVolume', { value: volume / 100 })
    this.changeVideoState({ volume })
  }

  /** 回复默认音量 */
  public recoverVolumePlay() {
    this.channel.send('submitMute', { mute: false })
    // 初始默认声音设置成75
    this.changeVideoState({ volume: 75 })
    this.channel.send('submitVolume', { value: this.videoState.volume / 100 })
  }

  /** 音量重置为之前的静音状态 */
  public resetVolumePlay() {
    this.channel.send('submitMute', { mute: true })
    this.changeVideoState({ volume: 0 })
    this.channel.send('submitVolume', { value: this.videoState.volume / 100 })
  }

  /**
   * 根据给定的进度跳转视频播放节点
   * @param progress 0 - 100
   */
  public seekVideoByProgress(progress: number) {
    const targetTime = Math.ceil(this.videoState.duration * (progress / 100))
    console.log('call seekVideoByProgress', targetTime)
    this.channel.send('seek', { timestamp: targetTime })
    this.changeVideoState({ current: targetTime, progress })
  }

  public seekVideoByTimestamp(timestamp: number) {
    const { progress } = this.getCurrentVideoTime(timestamp)
    if (progress >= 0 && progress <= 100) {
      this.seekVideoByProgress(progress)
    }
  }

  /**
   * 设置视频播放速率
   */
  public setVideoPlaybackRate(rate: number) {
    if (this.videoState.rate === rate) return

    this.changeVideoState({ rate: rate })
    this.channel.send('submitPlaybackRate', { playbackRate: rate })
  }

  @action
  public handleVideoPlaybackRate(rate: number) {
    if (this.videoState.rate === rate) return

    // 如果list没有追加
    if (this.videoRateList.findIndex(item => item.rate === rate) === -1) {
      console.log('特殊的视频速率')
      let nextState = [...this.videoRateList]
      nextState.push({ rate: rate, text: `${rate}倍` })
      nextState = nextState.sort((a, b) => a.rate - b.rate)

      this.videoRateList = nextState
    }

    this.changeVideoState({ rate: rate })
  }

  public updateFullScreen(fullscreen: boolean) {
    this.changeVideoState({ isFullScreen: fullscreen })
  }

  public addStateChangeListener(cb: (state: VideoState) => void) {
    this.stateChangeListener.push(cb)
  }

  public removeStateChangeListener(cb: (state: VideoState) => void) {
    this.stateChangeListener = this.stateChangeListener.filter(item => item !== cb)
  }

  private getCurrentVideoTime(playheadTime: number) {
    const { duration } = this.videoState
    const _playheadTime = Math.floor(playheadTime)

    return {
      current: _playheadTime,
      progress: (_playheadTime / duration) * 100
    }
  }

  /**
   * @deprecated
   */
  private parseQAList(list: Array<QAItem>): Array<QAItemParsed> {
    const result: Array<QAItemParsed> = []
    const parsed: string[] = []

    for (let i = 0; i <= list.length - 1; i++) {
      const { answererId, question, id, submitTime, submitor, submitorId } = list[i]

      if (parsed.includes(`${question}-${submitTime}`) && answererId !== '') {
        continue
      }
      // 只有提问没有回复
      if (answererId === '') {
        result.push({
          id: id,
          question: question,
          submitTime: moment(submitTime * 1000)
            .utcOffset(+8)
            .format('HH:mm:ss'),
          submitor: submitor,
          submitorId: submitorId,
          answerList: []
        })
      } else {
        const questions = list.filter(item => item.question === question && item.submitTime === submitTime)
        result.push({
          id: id,
          question: question,
          submitTime: moment(submitTime * 1000)
            .utcOffset(+8)
            .format('HH:mm:ss'),
          submitor: submitor,
          submitorId: submitorId,
          answerList: questions.map(q => {
            return {
              answer: q.answer,
              answerBy: q.answerBy,
              answerTime: moment(q.answerTime * 1000)
                .utcOffset(+8)
                .format('HH:mm:ss'),
              answererId: q.answererId
            }
          })
        })
      }
    }
    return result
  }

  async pageInit(courseId: string): Promise<boolean> {
    if (!courseId) return false

    this.setLoading(true)
    this.skeletonLoadingStart = Date.now()
    reportEntryLog(VideoLog.playback_skeleton_start, { time: this.skeletonLoadingStart, tabId: this.currentTabId })

    // 设置页面监听定时器
    if (this.pageReloadTimer) {
      clearTimeout(this.pageReloadTimer)
    }
    this.pageReloadTimer = setTimeout(() => {
      if (this.isLoading) {
        this.setPageLoadingTooLong(true)
        reportEntryLog(VideoLog.playback_loading_too_long, { tabId: this.currentTabId })
      }
    }, 10 * 1000)

    const that = this
    const tabId = socketClient.getTabId()
    const result = await this.$ajax.post(EnumApiUser.VideoInit, {
      courseId,
      tabId
    })

    if (result === true && !this.pollingLoop) {
      this.pollingLoop = new Loop(
        async () => {
          try {
            await this.$ajax.put<true>(EnumApiUser.VideoHeart, { courseId, tabId })
            return Loop.CheckResult.Loop
          } catch (e) {
            if (e === false) {
              that.updateBrowserWatchData(false)
              reportEntryLog(VideoLog.playback_user_kickout_by_xt, { type: 0, tabId: this.currentTabId })
              return Loop.CheckResult.Stop
            }

            // {"errCode":1031100069,"errMsg":"账号正在另一台设备登录","e":null}
            if (e && 'response' in e && e.response.errCode === 1031100069) {
              that.updateBrowserWatchData(true)
              reportEntryLog(VideoLog.playback_user_kickout_by_xt, { type: 1, tabId: this.currentTabId })
              return Loop.CheckResult.Stop
            }

            return Loop.CheckResult.Retry
          }
        },
        {
          pollingErrorRetryCount: 3,
          pollingInterval: 5 * 60 * 1000,
          checkResult(result) {
            return result
          }
        }
      )
    }

    return true
  }

  @action
  private setQAList(list: Array<QAItemParsed>) {
    this.qaList = list
  }

  @action
  private appendChatMessage(list: ChatItem[]) {
    this.chatMessageList = this.chatMessageList.concat(list)
  }

  @action
  private setChatMessageList(list: ChatItem[]) {
    this.chatMessageList = list
  }

  @action
  private setChapterList(list: ChapterItem[]) {
    this.chapterList = list
  }

  @action
  public initWidgetParams(params: WidgetParams) {
    this.widgetParams = params
  }

  @action updateBrowserWatchData(isLogout: boolean) {
    if (isLogout) {
      this.isWatchOnOtherBrowser = true
    } else {
      this.isWatchOnSameBrowser = true
    }
  }

  @action clearBrowserWatchData() {
    this.isWatchOnOtherBrowser = false
    this.isWatchOnSameBrowser = false
  }

  @action
  private changeVideoState(state: Partial<VideoState> = {}) {
    const nextState = lodash.merge(this.videoState, state)
    this.videoState = nextState
    this.stateChangeListener.forEach(cb => cb(nextState))
  }

  @action
  setLoading(isLoading: boolean) {
    // 正在加载 & 要关闭加载
    if (this.isLoading && !isLoading) {
      const now = Date.now()
      reportEntryLog(VideoLog.playback_skeleton_done, {
        time: now,
        duration: Date.now() - this.skeletonLoadingStart,
        tabId: this.currentTabId
      })

      // 已经加载出来了页面，取消刷新定时器
      if (this.pageReloadTimer) {
        clearTimeout(this.pageReloadTimer)
        this.pageReloadTimer = null
      }
    }

    this.isLoading = isLoading
  }

  @action
  setPageLoadingTooLong(isLoadingTooLong: boolean) {
    this.isPageLoadingTooLong = isLoadingTooLong
  }
}
