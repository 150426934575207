export enum EnumApiCoupon {
  // 完成订单关联的优惠券列表
  OrderAssocCoupon = '/coupon/api/coupon/activity/assoc/{productId}',
  // 用户的优惠券列表
  CouponPacks = '/coupon/api/coupon/packs',
  // 获取购买该商品最优优惠券组合
  CouponOptimalCompose = '/coupon/api/coupon/optimal/compose',
  // 获取订单关联优惠券
  CouponAssocByOrder = '/coupon/api/coupon/views'
}
