/*
 * @Author: Erlin
 * @CreateTime: 2020-09-06 18:15:02
 * @LastEditors: Erlin
 * @LastEditTime: 2020-09-26 16:54:01
 * @Description: 我晒出的作业列表
 */

import { BindAll } from 'lodash-decorators'
import { EnumApiHomework } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumMessage } from '../../languages'
import { Pagination } from '../basics/pagination'
/**
 * 我的作业
 * @export
 * @class ControllerMyWork
 * @extends {Pagination<any>}
 */
@BindAll()
export class ControllerMySunWork extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiHomework.MyMomentList,
      key: 'id'
    })
  }
  /**
   * 点赞
   * @param data 点赞的数据
   */
  async onLikes(data) {
    if (data.like) {
      throw EnumMessage.like_error
    }
    await this.$ajax.put(EnumApiHomework.MomentLike, {
      momentId: data.id,
      likeType: true
    })
    this.onUpdate(data, old => {
      old.likeCount++
      old.like = true
      return old
    })
  }

  /**
   * 删除作业
   */
  async onDelWork(id: string) {
    await this.$ajax.delete(EnumApiHomework.MomentDelete, {
      id
    })
    this.onRemove(id)
  }

  /**
   * 添加浏览量
   */
  async onAddbrowsenum(momentId: string) {
    this.onUpdate(momentId, old => {
      old.viewCount++
      return old
    })
    return this.$ajax.put(EnumApiHomework.MomentAddbrowsenum, { momentId })
  }
}
export default ControllerMySunWork
